import { Fragment, lazy } from "react";
const AppClient = lazy(()=> import("./app.client"));
const AppAdmin = lazy(()=> import("./app.admin"));

export default function App() {
    return (
        <Fragment>
            { process.env.REACT_APP_PLATFORM === 'client' && <AppClient/> }
            { process.env.REACT_APP_PLATFORM === 'admin' && <AppAdmin/> }
        </Fragment>
    );
}

